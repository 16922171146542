import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, InputGroup, Col, Row, Container, Modal, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCartShopping, faArrowLeft, faBagShopping, faCalendar} from '@fortawesome/free-solid-svg-icons';
import { getItemAttributeNameById } from '../functions';
import EditCartModal from '../elements/EditCartModal';
import { checkCouponThunk, clearCoupon } from '../store/coupons';
import { useDispatch, useSelector } from 'react-redux';
import EditFreeItemModal from '../elements/EditFreeItemModal';
import { getFreeItemsThunk } from '../store/freeitems';
import { placeOrderThunkPayInStore } from '../store/order';
import { useNavigate } from 'react-router-dom';
import CheckoutApp from '../elements/StripeCheckoutApp';
import loadingJson from '../assets/json/loading.json';
import Lottie from 'react-lottie-player';
import { Context } from '../context/AppContext';
import Header from '../components/Header';
import { checkIfLunchTime, isStoreOpen, showTimeError } from '../Utilts/helper';
import Swal from 'sweetalert2';


const opetion2 = [
  { value: "online", label: "Online Payment" },
  { value: "in-store", label: "In Store" },
];

const opetion1 = [
  { value: "online", label: "Online Payment" },
]

const CheckoutPage = ({ fee }) => {
  const navigate = useNavigate();
  const { language, restaurantId, changedCart, setChangedCart,sechduleTime, setScheduleTime} = useContext(Context);
  const [promoCode, setPromoCode] = useState('');
  const cart = JSON.parse(localStorage.getItem('cart')) || [];
  const freeItem = JSON.parse(localStorage.getItem(`freeItem`)) || [];
  const [orderMethod, setOrderMethod] = useState('pickup'); // pickup or delivery
  const [cartOpen, setCartOpen] = useState(true); // State to toggle cart items
  const [editCartModal, setEditCartModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentUserAddress, setCurrentUserAddress] = useState(null);
  const [addressVerified, setAddressVerified] = useState(false);
  const [addressErrors, setAddressErrors] = useState('');
  const [needDelivery, setNeedDelivery] = useState(false);
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [couponError, setCouponError] = useState('');
  const [tip, setTip] = useState(10);
  const [customTip, setCustomTip] = useState(0);
  const [showTipModal, setShowTipModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false); // State for payment modal
  const dispatch = useDispatch();
  const checkCoupon = useSelector(state => state.coupons.coupon);
  const [editFreeItem, setEditFreeItem] = useState(false);
  const freeItems = useSelector((state) => state.freeItems.freeitems);
  const [paymentOption, setPaymentOption] = useState('online');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(false);
  const [payinStoreLoading, setPayinStoreLoading] = useState(false);  // State for loading spinner
  const [paymentSubmitLoading, setPaymentSubmitLoading] = useState(false);  // State for loading spinner
  const [delivery_distance_fee_, setDeliveryDistanceFee] = useState(0);
  const [calcualtedMiles, setCalcualtedMiles] = useState(0) 


  const [needSchdule, setNeedSchdule] = useState(false)
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeModal, setTimeModal] = useState(false)


  useEffect(() => {
    const generateTimeSlots = () => {
      const slots = [];
      const currentDate = new Date();
      currentDate.setSeconds(0, 0); // Reset seconds and milliseconds
      currentDate.setHours(currentDate.getHours() + 1); // Add 1 hour to ensure the 1-hour gap
  
      let startTime = new Date();
      let endTime = new Date();
  
  
      // Set business hours based on the day of the week
        startTime.setHours(10, 30, 0, 0);  // 4:00 PM
        endTime.setHours(21, 30, 0, 0);    // 9:00 PM
     
  
      // If the current time is before the start of business hours, adjust it to the start time
      if (currentDate < startTime) {
        currentDate.setTime(startTime.getTime());
      }
  
      // Rounding the current time to the next 5-minute mark
      const minutes = currentDate.getMinutes();
      const roundedMinutes = Math.ceil(minutes / 5) * 5;
      currentDate.setMinutes(roundedMinutes);
  
      // Generating 5-minute interval slots from the current time to the end of business hours
      while (currentDate <= endTime) {
        const formattedDate = currentDate.toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
  
        slots.push(formattedDate); // Push available slot
  
        currentDate.setMinutes(currentDate.getMinutes() + 30); // Increase by 5 minutes
      }
  
      setTimeSlots(slots);
    };
  
    generateTimeSlots();
  }, []);
  

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    setScheduleTime(time)
  };

  const handleSchduleTimeClick = () => { 
    setNeedSchdule(true)

    setTimeModal(true)
  }



  useEffect(() => { 
      if(!showPaymentModal){
        setName('');
        setPhone('');
        setError('');
        setActiveTab(false);
      }
  },[showPaymentModal])



  useEffect(() => { 
    if (restaurantId) {
      dispatch(getFreeItemsThunk(restaurantId));
    }
  },[restaurantId, dispatch]);

  useEffect(() => {
    if (needDelivery) {
      handleScriptLoad();
      handleStoreMap();
    } else {
      handleStoreMap();
    }
  }, [needDelivery, currentUserAddress]);

  let autocomplete;
  const handleScriptLoad = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"] }
    );

    autocomplete.addListener("place_changed", handlePlaceSelect);
  };

  function handlePlaceSelect() {
    let place = autocomplete.getPlace();

    if (!place.geometry) {
      setAddressErrors('Please enter a valid address');
      document.getElementById("autocomplete").value = "";
      return;
    } else {
      const res = checkDistance(place.formatted_address);
      res.then((result) => {
        if (result) {
          setCurrentUserAddress(place.formatted_address);
          setAddressVerified(true);
          setAddressErrors('');
        }
      });
    }
  }

  const checkDistance = async (userAddress) => {
    const geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: userAddress }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const userLocation = results[0].geometry.location;
          const restaurantLocation = new google.maps.LatLng(33.9606397, -84.1338949);
  
          // Use the Distance Matrix API to get driving distance
          const service = new google.maps.DistanceMatrixService();
          service.getDistanceMatrix({
            origins: [userLocation],
            destinations: [restaurantLocation],
            travelMode: google.maps.TravelMode.DRIVING,
          }, (response, status) => {
            if (status === google.maps.DistanceMatrixStatus.OK) {
              const distanceInMeters = response.rows[0].elements[0].distance.value; // Get distance in meters
              const distanceInMiles = distanceInMeters * 0.000621371; // Convert meters to miles
              

              setCalcualtedMiles(distanceInMiles)
              // Check if the distance is within 10 miles
              if (distanceInMiles <= 6) {
                setAddressErrors('');
                setAddressVerified(true);
                resolve(true); // Address is verified and within delivery range
  
                // Set delivery fee based on distance range
                if (distanceInMiles >= 0 && distanceInMiles <= 1) {
                  setDeliveryDistanceFee(+fee.delivery_zero_to_one);
                } else if (distanceInMiles > 1 && distanceInMiles <= 2) {
                  setDeliveryDistanceFee(+fee.delivery_one_to_two);
                } else if (distanceInMiles > 2 && distanceInMiles <= 3) {
                  setDeliveryDistanceFee(+fee.delivery_two_to_three);
                } else if (distanceInMiles > 3 && distanceInMiles <= 4) {
                  setDeliveryDistanceFee(+fee.delivery_three_to_four);
                } else if (distanceInMiles > 4 && distanceInMiles <= 5) {
                  setDeliveryDistanceFee(+fee.delivery_four_to_five);
                } else if (distanceInMiles > 5) {
                  const extraMiles = Math.floor(distanceInMiles - 5);
                  const baseFee = +fee.delivery_five_greater; // Fee for distances > 5
                  // Add the extra fee for each additional mile
                  setDeliveryDistanceFee(+fee.delivery_four_to_five + baseFee * (extraMiles + 1));
                }
              } else {
                // Address is more than 10 miles away
                setAddressErrors('Sorry, we cannot deliver to places more than 6 miles away.');
                setAddressVerified(false);
                resolve(false);
              }
            } else {
              setAddressErrors('Error calculating the distance. Please try again.');
              setAddressVerified(false);
              resolve(false);
            }
          });
        } else {
          // Geocoding failed, show an error
          setAddressErrors('Unable to geocode the address. Please try again later.');
          setAddressVerified(false);
          resolve(false);
        }
      });
    });
  };
  
  
  
  const handleStoreMap = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    const mapOptions = {
      center: new google.maps.LatLng(33.9606397, -84.1338949),
      zoom: needDelivery ? 10 : 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#ffffff" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#000000" }] },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [{ color: "#bdbdbd" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#d6d6d6" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#737373" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#a9dfbf" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [{ color: "#fdfdfd" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [{ color: "#757575" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#eeeeee" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#616161" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#e5e5e5" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#737373" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#b3cde0" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
      ],
    };
    
    
    const map = new google.maps.Map(document.getElementById("gmp-map"), mapOptions);

    // Add marker
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(33.9606397, -84.1338949),
      title: "Ooka Sushi",
      map: map,
    });

    if (needDelivery) {
      // Add circle overlay
      new google.maps.Circle({
        strokeColor: "black",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "gray",
        fillOpacity: 0.35,
        map: map,
        center: new google.maps.LatLng(33.9606397, -84.1338949),
        radius: 16093.44, // 10 miles in meters,
        
      });
    }
  };

  //handle coupon
  const checkCurrentCoupon = async (e) => {
    e.preventDefault();
    setCheckingCoupon(true);
    setCouponError('');
    try { 
       await dispatch(checkCouponThunk(promoCode));
    } catch (error) {
      setCheckingCoupon(false);
    } finally {
      setCheckingCoupon(false);
    }
  };

  const handleRemoveCoupon = () => {
    setPromoCode('');
    setCouponError('');
    dispatch(clearCoupon(''));
  };

  const handleEditCartModal = (item) => {
    setSelectedItem(item);
    setEditCartModal(true);
  };

  const calculateSubtotal = () => {
    let subtotal = cart.reduce(
      (total, cartItem) => total + cartItem.Item.price * cartItem.quantity,
      0
    );  

    cart.forEach((cartItem) => {
      cartItem.options.forEach((option) => {
        subtotal += option.priceModifier * cartItem.quantity;
      });
    });

    return subtotal.toFixed(2);


  };

  const calculateTaxes = () => {
    return (calculateSubtotal() * fee?.tax).toFixed(2);
  };

  const calculateDiscount = () => {
    const subtotal = parseFloat(calculateSubtotal());
    let discount = 0;

    if (checkCoupon && subtotal >= parseFloat(checkCoupon.min_require)) {
      if (checkCoupon.discount_type === 'Percentage') {
        discount = (subtotal * (parseFloat(checkCoupon.discount) / 100)).toFixed(2);
      } else {
        discount = parseFloat(checkCoupon.discount);
      }
    }

    return discount;
  };

  const calculateServiceFee = () => {
    const subtotal = parseFloat(calculateSubtotal());
    return (subtotal * fee?.service_fee + parseFloat(fee?.commission_fee))?.toFixed(2) ;
  };

  const calculateTip = () => {
    const subtotal = parseFloat(calculateSubtotal());
    return ((subtotal * tip) / 100).toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const taxes = parseFloat(calculateTaxes());
    const discount = calculateDiscount();
    const commissionFee = parseFloat(fee?.commission_fee);
    const serviceFee = orderMethod === 'delivery' ? parseFloat(calculateServiceFee()) : 0;
    const deliveryFee = orderMethod === 'delivery' ? parseFloat(fee?.delivery_fee) : 0;
    const distancefee = orderMethod === 'delivery' ? delivery_distance_fee_ : 0
    const tipAmount = parseFloat(customTip || calculateTip());

    let total = (subtotal + taxes - discount + serviceFee + deliveryFee + tipAmount + distancefee).toFixed(2);
    if (orderMethod === 'pickup') {
      total = (parseFloat(total) + commissionFee).toFixed(2);
    }
    return total;
  };

  useEffect(() => { 
    if(checkCoupon?.min_require && parseFloat(calculateSubtotal()) < parseFloat(checkCoupon.min_require)){
      setCouponError('Minimum order amount of $' + checkCoupon.min_require + ' is required to use this coupon.');
    }
  },[checkCoupon, calculateSubtotal()])

  const handleTipSelect =  (percentage) => {
    setTip(percentage)
    setCustomTip(0)
 };

 const handleCustomTip = () => {
  setTip(0);
   setShowTipModal(true);
 };

 const incrementTip = () => setCustomTip(prevTip => +prevTip + 1);
 const decrementTip = () => setCustomTip(prevTip => +Math.max(0, prevTip - 1));


 const handleCustomTipChange = (e) => {
   if (/^\d*$/.test(e.target.value)) { // Allow only digits
     setCustomTip(Number(e.target.value));
   }
   setTip(0); // Reset tip percentage
 };


  const handleEditFreeItem = (item) => {
    setSelectedItem(item);
    setEditFreeItem(true);
  };
  
  const needAmountReached = () => {
    // Iterate through freeItems array
    const freeItemed = JSON.parse(localStorage.getItem('freeItem'));

    if(freeItemed?.Item){
    for (const freeItem of freeItems) {
      // Find the matching ReedemableItem based on item 
      for(const item of freeItem.ReedemableItems){ 
        if(item?.Item?.id === freeItemed?.Item?.id){
          return +freeItem.need_amount <= +calculateSubtotal();
        }
      }
    }} else { 
      return true;
    }
  };

  const handlePlaceOrder = () => {
    if (!isStoreOpen()) { 
      showTimeError();
      return;
    }
  
    const lunchitem = cart.filter(item => item.Item.name.includes('(L)'));
  
    if (!checkIfLunchTime() && lunchitem.length > 0) { 
      Swal.fire({ 
        icon: 'error',
        title: language === 'cn' 
          ? '午餐供应到下午2:30，请从购物车中移除午餐项以继续。' 
          : 'Lunch items end at 2:30PM. Please remove lunch items from cart to proceed.',
      });
      return;
    }
  
    if (orderMethod === 'delivery' && currentUserAddress.length < 9) { 
      Swal.fire({ 
        icon: 'error', 
        title: language === 'cn' 
          ? '请重新输入您的地址。' 
          : 'Please re-enter your address again.'
      });
      return;
    }
  
    if (!name || !phone) {
      setError(language === 'cn' 
        ? '请输入您的姓名和电话号码。' 
        : 'Please enter your name and phone number.'
      );
      return;
    }
  
    // Only allow numbers for phone, and length of 10
    if (!/^\d{10}$/.test(phone)) {
      setError(language === 'cn' 
        ? '请输入有效的电话号码，仅允许数字。' 
        : 'Please enter a valid phone number. Only numbers are allowed.'
      );
      return;
    }
  
    setShowPaymentModal(true);
  };
  
  const handlePaymentMethodChange = (selectedOption) => {
    setPaymentOption(selectedOption);
  };
  
  const handleContinueCheckout = () => {
    if (!paymentOption) { 
      setError(language === 'cn' 
        ? '请选择一种支付方式。' 
        : 'Please select a payment method.'
      );
      return;
    }
  
    if (!name || !phone) {
      setError(language === 'cn' 
        ? '请输入您的姓名和电话号码。' 
        : 'Please enter your name and phone number.'
      );
      return;
    }
  
    // Only allow numbers for phone, and length of 10
    if (!/^\d{10}$/.test(phone)) {
      setError(language === 'cn' 
        ? '请输入有效的电话号码，仅允许数字。' 
        : 'Please enter a valid phone number. Only numbers are allowed.'
      );
      return;
    }
  
    setActiveTab(true);
  };
  

  
  const handlePayInStore = async () => {
    setPayinStoreLoading(true);
    try {
      await dispatch(placeOrderThunkPayInStore(
        restaurantId,
        paymentOption,
        name,
        phone,
        customTip || calculateTip(),
        currentUserAddress || null,
        cart,
        calculateDiscount() > 0 ? checkCoupon?.coupon_id : null,
        freeItem?.Item ? freeItem : null,
        'noidpayedinstore',
        selectedTime ? selectedTime : 'ASAP'

      ))
      .then(() => {

      setChangedCart(!changedCart);
      setPayinStoreLoading(false);
      
      navigate('/order-confirmed/zds/gwzc/ASC/2/4');
      })
    } catch (error) {
      setPayinStoreLoading(false);
      console.error('An error occurred:', error);
      setError('An error occurred. Please try again later.');
    }
  }
  

  const goBack = () => {
    setActiveTab(false);
  }

  const addDelieveryFees = () => { 

    let result = +delivery_distance_fee_ + +fee.delivery_fee
    return result.toFixed(2)
  }


  
  return (
    <>
    <Header />
    <Container className="checkout-page bg-white" style={{marginTop:'80px'}}> 
      <Row>
        <Col md={7}>
        <div onClick={() => navigate('/order')} className="text-decoration-none text-dark">
                <h5 style={{ cursor: 'pointer' }}>
                  <i className="fas fa-long-arrow-alt-left me-2"></i> 
                  {language === 'cn' ? '返回点餐':'continue shopping'}
                </h5>
              </div>
          <div className="section pickup-details">

            <div className='d-flex row align-items-center justify-content-between'>
            <h5 className='col-5'>     {language === 'cn' ? '结账':'Checkout'}
            </h5>
            <div className='d-flex col-7 '>
              <Button 
                variant={orderMethod === 'pickup' ? "primary" : "outline-primary"} 
                className={`pickup-option  ${orderMethod === 'pickup' ? 'active' : ''}`} 
                onClick={() => {
                  setOrderMethod('pickup');
                  setNeedDelivery(false);
                  setCurrentUserAddress(null)
                }}
              >
                {language === 'cn' ? '取餐':'Pickup'} 
              </Button>
              <Button 
                variant={orderMethod === 'delivery' ? "primary" : "outline-primary"} 
                className={`pickup-option  ${orderMethod === 'delivery' ? 'active' : ''}`} 
                onClick={() => {
                  setOrderMethod('delivery');
                  setNeedDelivery(true);
                }}
              >
                {language === 'cn' ? '送餐':'Delivery'} 
              </Button>
            </div>
            </div>

            <div className="section schedule-options">
  <h5 style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
  {needDelivery ? (language === 'cn' ? '送餐服务' : 'Delivery Options') : (language === 'cn' ? '取餐服务' : 'Pickup Options')}


  </h5>

  <div
className={`d-flex align-items-center justify-content-between ${!needSchdule ? 'bg-primary text-white' : ''}`}
style={{
      border: '1px solid black',
      padding: '12px',
      borderRadius: '8px',
      marginBottom: '1rem',
    }}
    onClick={() => {
      setNeedSchdule(false) 
      setScheduleTime(null)
      setSelectedTime(null)
      }}
  >
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon={faBagShopping} size="2x" style={{ marginRight: '1rem' }} />
      <div>
      <p style={{ margin: 0, fontWeight: '500' }}>
  {language === 'cn' ? '标准 15-30 分钟' : 'Standard 15-30 min'}
</p>
<p style={{ margin: 0, fontSize: '0.85rem' }}>
  {language === 'cn' ? '尽快' : 'ASAP'}
</p>

      </div>
    </div>
  </div>
{needDelivery ? <></> : (
  <div
className={`d-flex align-items-center justify-content-between ${needSchdule ? 'bg-primary text-white' : ''}`}
style={{
      border: '1px solid black',
      padding: '12px',
      borderRadius: '8px',
    }}
    onClick={handleSchduleTimeClick}

  >
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon={faCalendar} size="2x" style={{ marginRight: '1rem' }} />
      <div>
        <p style={{ margin: 0, fontWeight: '500' }}>
  {language === 'cn' ? '安排' : 'Schedule'} {needDelivery ? (language === 'cn' ? '送餐' : 'Delivery') : (language === 'cn' ? '取餐' : 'Pickup')}
</p>
{
  !selectedTime ? (
    <p style={{ margin: 0, fontSize: '0.85rem' }}>
      {language === 'cn' ? '选择一个时间' : 'Choose a time'}
    </p>
  ) : (
    selectedTime
  )
}

      </div>
    </div>
  </div>
)}
</div>
            
            
            <div className='gmp-map' id='gmp-map'></div>
            <p className="pickup-location text-center">
  {language === 'cn' ? '十秒到过桥米线' : 'Crossing Bridge Rice Noodle'}<br />
  <br />
  {language === 'cn' ? (
    <>
      周一至周日：上午10:30AM - 下午9:30PM<br />
      周五至周六：上午10:30 - 下午10:30PM
    </>
  ) : (
    <>
      Mon-Sun: 10:00 AM - 9:30 PM
      <br />
      Fri-Sat: 10:30 AM - 10:00 PM
    </>
  )}
  </p>


          </div>
          {orderMethod === 'delivery' && (
<div className="section delivery-details">
  <h5>{language === 'cn' ? '送餐详情' : 'Delivery Details'}</h5>
  <Form>
    <Form.Group controlId="autocomplete" className="mb-3">
      <Form.Label>{language === 'cn' ? '输入送餐地址' : 'Enter Delivery Address'}</Form.Label>
      <Form.Control type="text" placeholder={language === 'cn' ? '请输入您的地址' : 'Enter your address'} />
    </Form.Group>
    {calcualtedMiles > 0 && (
      <p className="text-blue">
        {language === 'cn' ? '计算的驾驶距离' : 'Calculated Distance driving'}: {calcualtedMiles?.toFixed(2)} {language === 'cn' ? '英里' : 'miles'}
      </p>
    )}
    {addressErrors && <p className="text-danger">{addressErrors}</p>}
  </Form>
</div>

          )}
        </Col>
        <Col md={5}>
          <div className="section cart-summary">
            <h5 className="cart-summary-header" onClick={() => setCartOpen(!cartOpen)}>
              <FontAwesomeIcon icon={faCartShopping} className="toggle-icon" />
  {language === 'cn' ? '购物车' : 'Cart summary'} ({cart.length} {language === 'cn' ? '件菜品' : `item${cart.length !== 1 ? 's' : ''}`})
              <FontAwesomeIcon icon={cartOpen ? faChevronUp : faChevronDown} className="toggle-icon" />
            </h5>
            {+cart.length > 0 && (
            <>
                      <button className="btn btn-primary w-100 mb-2" onClick={() => { 
                        localStorage.removeItem('cart');
                        localStorage.removeItem('freeItem');
                        setChangedCart(!changedCart);
                      }}>
{language === 'cn' ? '清空购物车' : 'Clear Cart'}
                      </button>

            <div style={{borderBottom:'1px solid gray'}}></div>
            </>
              )}            
            {cartOpen && (
              <>
              <div>

              {freeItem?.Item && (
                    <div
                      onClick={() => handleEditFreeItem({
                        id: freeItem?.Item.id,
                        name: freeItem?.Item?.name,
                        price: freeItem?.Item.price,
                        quantity: freeItem?.quantity,
                        special_instructions: freeItem?.special_instructions,
                        image_url: freeItem?.Item?.image_url,
                        Restaurant_Item_Attributes: freeItem?.Item?.Restaurant_Item_Attributes,
                        options: freeItem?.options,
                      })}
                      className="cart-item d-flex align-items-start mb-3"
                    >
                          <img 
                      src={freeItem.Item.image_url || 'https://theme-assets.getbento.com/sensei/3914814.sensei/assets/images/catering-item-placeholder-704x520.png'} 
                      alt={freeItem.Item.name} 
                      className="cart-item-image me-3" 
                    />
                    <div className="cart-item-details flex-grow-1">
                    <p className='item-name mb-1'>{language === 'en' ? freeItem?.Item?.name : freeItem?.Item?.chinese_name}</p>
                            {freeItem?.options?.map((attribute) => (
                              <p className="item-attribute small m-0" key={attribute.id}>
                                {getItemAttributeNameById(attribute.id, freeItem.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                              </p>
                            ))}
                            {freeItem?.special_instructions && (
                              <p className="item-instructions small m-0">
                                <strong>**</strong> {freeItem?.special_instructions}
                              </p>
                            )}
                          </div>
                          <div className="cart-item-quantity d-flex align-items-center justify-content-center">
                            <span className="item-quantity">1</span>
                            </div>
                            <p className="item-price ms-3">Free</p>
                            

                    </div>
                    
                  )  
                  }
              </div>

              <div>
                {cart.map((item, index) => (
                  <div key={index} className="cart-item d-flex align-items-start mb-3"
                    onClick={() => handleEditCartModal({
                      id: item?.Item.id,
                      name: item?.Item?.name,
                      price: item?.Item.price,
                      quantity: item?.quantity,
                      special_instructions: item?.special_instructions,
                      image_url: item?.Item?.image_url,
                      Restaurant_Item_Attributes: item?.Item?.Restaurant_Item_Attributes,
                      options: item?.options,
                      index: index,
                    })}
                  >
                    <img 
                      src={item.Item.image_url || 'https://theme-assets.getbento.com/sensei/3914814.sensei/assets/images/catering-item-placeholder-704x520.png'} 
                      alt={item.Item.name} 
                      className="cart-item-image me-3" 
                    />
                    <div className="cart-item-details flex-grow-1">
                      <p className="item-name mb-1">{language === 'en' ? item?.Item?.name : item?.Item?.chinese_name}</p>
                      {item?.options?.map((attribute, idx) => (
                        <p className="item-attribute small m-0" key={idx}>
                          {getItemAttributeNameById(attribute.id, item.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                        </p>
                      ))}
                      {item?.special_instructions && (
                        <p className="item-instructions small m-0">
                          <strong>**</strong> {item?.special_instructions}
                        </p>
                      )}
                    </div>
                    <div className="cart-item-quantity d-flex align-items-center justify-content-center">
                      <span className="item-quantity">{item.quantity}</span>
                    </div>
                    <p className="item-price ms-3">${item.price}</p>
                  </div>
                ))}
              </div>
              </>
            )}
          </div>
<div className="section promotion">
  <h5>{language === 'cn' ? '促销' : 'Promotion'}</h5>
  <InputGroup>
    <Form.Control
      type="text"
      placeholder={language === 'cn' ? '添加促销代码' : 'Add promo code'}
      value={promoCode}
      onChange={(e) => setPromoCode(e.target.value)}
    />
    <Button variant="outline-primary" onClick={(e) => checkCurrentCoupon(e)}>
      {checkingCoupon ? (
        <span className="spinner-border spinner-border-sm"></span>
      ) : language === 'cn' ? '应用' : 'Apply'}
    </Button>
  </InputGroup>
  {checkCoupon && parseFloat(calculateSubtotal()) >= parseFloat(checkCoupon.min_require) && (
    <div className="mt-2">
      <p className="text-success">
        {language === 'cn' ? '优惠券已应用！折扣：' : 'Coupon applied! Discount: $'}{calculateDiscount()}
      </p>
      <Button variant="link" onClick={handleRemoveCoupon}>
        {language === 'cn' ? '移除优惠券' : 'Remove coupon'}
      </Button>
    </div>
  )}
  {checkCoupon && parseFloat(calculateSubtotal()) < parseFloat(checkCoupon.min_require) && (
    <div className="mt-2">
      <p className="text-danger">
        {language === 'cn'
          ? `使用此优惠券需要最低订单金额 ${checkCoupon.min_require} 美元。`
          : `Minimum order amount of $${checkCoupon.min_require} is required to use this coupon.`}
      </p>
    </div>
  )}
  {checkCoupon && checkCoupon.message && (
    <div className="mt-2">
      <p className="text-danger">{checkCoupon.message}</p>
    </div>
  )}
  {!checkCoupon && (
    <div className="mt-2">
      <p className="text-danger">
        {couponError || (language === 'cn' ? '无效的优惠券代码。' : 'Invalid coupon code.')}
      </p>
    </div>
  )}
</div>

<div className="section customer-detail">
  <h5 style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
    {language === 'cn' ? '客户信息*' : 'Customer Info*'}
  </h5>

  <div className="row">
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
      <input
        type="text"
        className="form-control input-style"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={language === 'cn' ? '姓名' : 'Name'}
        required
        maxLength={20}
      />
    </div>
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
      <input
        type="tel"
        className="form-control input-style"
        id="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder={language === 'cn' ? '电话（10位数字，无破折号或空格）' : 'Phone (10 digits, no dashes or spaces)'}
        required
        maxLength={10}
      />
    </div>
  </div>

</div>
        
<div className="section order-total">
  <h5>{language === 'cn' ? '订单总额' : 'Order total'}</h5>
  <div className="order-summary">
    <p>{language === 'cn' ? '小计' : 'Subtotal'} <span>${calculateSubtotal()}</span></p>
    <p>{language === 'cn' ? '税费' : 'Taxes'} <span>${calculateTaxes()}</span></p>
    {/* {orderMethod === 'pickup' && (
      <p>{language === 'cn' ? '佣金费' : 'Commission Fee'} <span>${parseFloat(fee?.commission_fee).toFixed(2)}</span></p>
    )} */}
    {checkCoupon && parseFloat(calculateSubtotal()) >= parseFloat(checkCoupon.min_require) && (
      <p>{language === 'cn' ? '折扣' : 'Discount'} <span>-${calculateDiscount()}</span></p>
    )}
    {orderMethod === 'delivery' && (
      <>
        <p>{language === 'cn' ? '服务费' : 'Service Fee'} <span>${(calculateServiceFee() - 0.29)?.toFixed(2)}</span></p>
        <p>
          {language === 'cn' ? '送餐费' : 'Delivery Fee'} 
          {addressVerified ? (
            <span>${addDelieveryFees()}</span>
          ) : (
            <span>{language === 'cn' ? '等待地址验证' : 'Waiting Address'}</span>
          )}
        </p>
        {/* <p>{language === 'cn' ? '零售配送费' : 'Retail Delivery Fee'} <span>$0.29</span></p> */}
      </>
    )}
    <p>{language === 'cn' ? '小费' : 'Tip'} <span>${parseFloat(customTip || calculateTip()).toFixed(2)}</span></p>
    <div className="tip-options-container">
  {needDelivery ? (
    <>
      <Button
        variant={tip === 15 ? "primary" : "outline-primary"}
        className={`tip-option custom-button ${tip === 10 ? 'active' : ''}`}
        onClick={() => handleTipSelect(10)}
      >
        {language === 'cn' ? '10% 小费' : '10%'}
      </Button>
      <Button
        variant={tip === 18 ? "primary" : "outline-primary"}
        className={`tip-option custom-button ${tip === 15 ? 'active' : ''}`}
        onClick={() => handleTipSelect(15)}
      >
        {language === 'cn' ? '15% 小费' : '15%'}
      </Button>
      <Button
        variant={tip === 20 ? "primary" : "outline-primary"}
        className={`tip-option custom-button ${tip === 18 ? 'active' : ''}`}
        onClick={() => handleTipSelect(18)}
      >
        {language === 'cn' ? '18% 小费' : '18%'}
      </Button>
    </>
  ) : (
    <>
      <Button
        variant={tip === 0 ? "primary" : "outline-primary"}
        className={`tip-option custom-button ${tip === 0 ? 'active' : ''}`}
        onClick={() => handleTipSelect(0)}
      >
        {language === 'cn' ? '0% 小费' : '0%'}
      </Button>
      <Button
        variant={tip === 10 ? "primary" : "outline-primary"}
        className={`tip-option custom-button ${tip === 10 ? 'active' : ''}`}
        onClick={() => handleTipSelect(10)}
      >
        {language === 'cn' ? '10% 小费' : '10%'}
      </Button>
      <Button
        variant={tip === 15 ? "primary" : "outline-primary"}
        className={`tip-option custom-button ${tip === 15 ? 'active' : ''}`}
        onClick={() => handleTipSelect(15)}
      >
        {language === 'cn' ? '15% 小费' : '15%'}
      </Button>
    </>
  )}
  <Button
    variant={tip === -1 ? "primary" : "outline-primary"}
    onClick={handleCustomTip}
  >
    {language === 'cn' ? '自定义' : 'Custom'}
  </Button>
</div>

    <p>{language === 'cn' ? '总计' : 'Total'} <span>${calculateTotal()}</span></p>
  </div>
</div>

      <p className="text-danger">
  {!needAmountReached() && (language === 'cn' ? '您需要达到最低金额才能兑换免费商品。' : 'You need to reach the minimum amount to redeem the free item.')}
</p>
{orderMethod === 'delivery' && calculateSubtotal() < 20 && (
  <p className="text-danger">
    {language === 'cn' ? '送餐需要小计至少 $20 的最低订单金额。' : 'Minimum order amount of $20 in subtotal is required for delivery.'}
  </p>
)}
{orderMethod === 'delivery' && !addressVerified && (
  <p className="text-danger">
    {language === 'cn' ? '请输入餐厅方圆5英里内的有效地址。' : 'Please enter a valid address within 5 miles of the restaurant.'}
  </p>
)}
{isStoreOpen() === false && (
  <p className="text-danger">
    {language === 'cn' ? '抱歉，我们目前已关闭。请参考我们的营业时间。' : 'Sorry, we are currently closed. Refer to our store hours.'}
  </p>
)}
{error && (
  <p className="text-danger">
    {error}
  </p>
)}

<button
  className="place-order-btn"
  disabled={
    (orderMethod === 'delivery' && !addressVerified) ||
    !needAmountReached() ||
    (orderMethod === 'delivery' && calculateSubtotal() < 20) ||
    isStoreOpen() === false
  }
  onClick={handlePlaceOrder}
>
  {language === 'cn' ? '下单' : 'Place Order'}
</button>

        </Col>
      </Row>
      <EditCartModal modal={editCartModal} item={selectedItem} setModal={setEditCartModal} />
      <EditFreeItemModal  modal={editFreeItem} item={selectedItem} setModal={setEditFreeItem} />
<Modal show={showTipModal} onHide={() => setShowTipModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>{language === 'cn' ? '输入自定义小费' : 'Enter Custom Tip'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form.Group className="d-flex align-items-center justify-content-center">
      <button className="btn btn-primary" onClick={decrementTip}>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <Form.Control
        type="tel"
        min="0"
        value={customTip}
        onChange={handleCustomTipChange}
        placeholder={language === 'cn' ? '输入自定义小费金额' : 'Enter custom tip amount'}
        className="mx-2 text-center"
        style={{ maxWidth: '100px' }}
      />
      <button className="btn btn-primary" onClick={incrementTip}>
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </Form.Group>
  </Modal.Body>
  <Modal.Footer>
    <button className="w-100 btn btn-dark" onClick={() => setShowTipModal(false)}>
      {language === 'cn' ? '保存' : 'Save'}
    </button>
  </Modal.Footer>
</Modal>

      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} backdropClassName="overlay" centered size="lg">

        
        <>
          <Modal.Header closeButton>
<Modal.Title>{language === 'cn' ? '支付方式' : 'Payment Method'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
  {paymentSubmitLoading ? (
    <Lottie 
      loop
      animationData={loadingJson}
      play
    />
  ) : (
    <>
      <div className="d-flex">
{needDelivery ? (
  <>
    <button
      className={`payment-button w-100 ${paymentOption === 'online' ? 'primary' : 'outline-primary'}`}
      onClick={() => handlePaymentMethodChange('online')}
    >
      {language === 'cn' ? '在线支付' : 'Online Payment'}
    </button>
    {/* <button
      className={`payment-button ${paymentOption === 'in-store' ? 'primary' : 'outline-primary'}`}
      onClick={() => handlePaymentMethodChange('in-store')}
    >
      {language === 'cn' ? '现金' : 'Cash'}
    </button> */}
  </>
) : (
  <>
    <button
      className={`payment-button ${paymentOption === 'online' ? 'primary' : 'outline-primary'}`}
      onClick={() => handlePaymentMethodChange('online')}
    >
      {language === 'cn' ? '在线支付' : 'Online Payment'}
    </button>
    <button
      className={`payment-button ${paymentOption === 'in-store' ? 'primary' : 'outline-primary'}`}
      onClick={() => handlePaymentMethodChange('in-store')}
    >
      {language === 'cn' ? '店内支付' : 'In Store'}
    </button>
  </>
)}

      </div>

      <div>
        {/* {error && <p className="text-danger">{error}</p>} */}
        <div className="row mb-2">
          {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
            <input
              type="text"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              style={{ textAlign: 'center' }}
              required
              disabled={activeTab}
              maxLength={20}
            />
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
            <input
              type="tel"
              className="form-control"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone (10 digits, no dashes or spaces)"
              style={{ textAlign: 'center' }}
              required
              disabled={activeTab}
              maxLength={10}
            />
          </div> */}
      
            <>
          
              {paymentOption === 'online' && (
                   <>
                   <CheckoutApp
                   tip={customTip || calculateTip()}
                   restaurantId={restaurantId}
                   name={name}
                   phone={phone}
                   cartItems={cart}
                   coupon={calculateDiscount() > 0 ? checkCoupon?.coupon_id : null}
                   address={needDelivery ? currentUserAddress : 'Pickup'}
                   distanceFee={delivery_distance_fee_}
                   />   
                 </>
              )}
          
              {paymentOption === 'in-store' && (
                <>
                  <button className="black-button p-3 mt-3" onClick={handlePayInStore} disabled={payinStoreLoading}>
                    {payinStoreLoading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      'Place Order'
                    )}
                  </button>
                </>
              )}
            </>

        </div>
      </div>
    </>
  )}
</Modal.Body>

        </>
      </Modal>

<Modal show={timeModal} onHide={() => setTimeModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>{language === 'cn' ? '安排时间' : 'Schedule Time'}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group>
        <Form.Label>
          {language === 'cn' ? '选择一个' : 'Select a'} {needSchdule ? (language === 'cn' ? '送餐' : 'delivery') : (language === 'cn' ? '取餐' : 'pickup')} {language === 'cn' ? '时间' : 'time'}
        </Form.Label>
        <div 
          className="d-flex flex-column" 
          style={{
            maxHeight: '500px',
            overflowY: 'scroll',
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '6px',
            backgroundColor: '#f9f9f9',
          }}
        >
          {timeSlots.length > 0 ? (
            timeSlots.map((slot, index) => (
              <span
                key={index}
                className={`time-slot mb-2 p-2 ${selectedTime === slot ? 'selected-time-slot' : ''}`}
                onClick={() => handleTimeSelect(slot)}
                style={{
                  cursor: 'pointer',
                  border: '1px solid black',
                  borderRadius: '4px',
                  fontSize: '1.1rem',
                  color: selectedTime === slot ? '#fff' : '#ee2737',
                  backgroundColor: selectedTime === slot ? '#ee2737' : '#fff',
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                }}
              >
                {slot}
              </span>
            ))
          ) : (
            <div>{language === 'cn' ? '没有可用的时间段' : 'No available time slots'}</div>
          )}
        </div>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button 
      className="w-100"
      variant="primary" 
      onClick={() => setTimeModal(false)}
    >
      {language === 'cn' ? '确认' : 'Confirm'}
    </Button>
  </Modal.Footer>
</Modal>

    </Container>
    </>
  );
};

export default CheckoutPage;
