import { useNavigate } from "react-router-dom";
import banner from './banner.PNG';
import { useContext } from "react";
import { Context } from "../context/AppContext";

const MainBanner2 = () => {
  const navigate = useNavigate();
  const { language } = useContext(Context);

  const orderOnlineText = language === 'cn' ? '在线点餐' : 'Order Online';
  const viewMenuText = language === 'cn' ? '查看菜单' : 'View Menu';

  return (
    <>
      <div 
        className="main-bnr-three overflow-hidden container banner d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          marginTop:'80px',
          borderRadius:'16px'
        }}
      >
      </div>
      {/* Buttons at the Bottom */}
      <div className="d-flex justify-content-center align-items-center mt-2">
        <div
          onClick={() => navigate('/order')}
          className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
        >
          <span>{orderOnlineText}</span>
        </div>
        <div
          onClick={() => navigate("/menu")}
          className="btn btn-md shadow-primary btn-hover-1 bg-black ms-3"
        >
          <span>{viewMenuText}</span>
        </div>
      </div>
    </>
  );
};

export default MainBanner2;
