import { Link } from "react-router-dom";
import { useRef, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Context } from "../context/AppContext";

const Footer2 = () => {
  const heartRef = useRef(null);
  const { language } = useContext(Context);

  const footerTitle = language === 'cn' ? '十秒过桥米线' : 'Crossbridge Rice Noodle';
  const addressText = language === 'cn'
    ? '2180 Pleasant Hill Road Suite B10, Duluth, GA, 30096'
    : '2180 Pleasant Hill Road Suite B10, Duluth, GA, 30096';
  const businessHoursText = language === 'cn' ? '营业时间' : 'Business Hours';
  const carryoutHoursText = language === 'cn' ? '外带和送货时间' : 'Carryout & Delivery Hours';
  const monToSunText = language === 'cn' ? (
    <>
      周一至周日：上午10:30AM - 下午9:30PM<br />
      周五至周六：上午10:30 - 下午10:30PM
    </>
  ) : (
    <>
      Mon-Sun: 10:00 AM - 9:30 PM
      <br />
      Fri-Sat: 10:30 AM - 10:00 PM
    </>
  );  
  const quickLinksTitle = language === 'cn' ? '快速链接' : 'Quick Links';
  const ourServicesTitle = language === 'cn' ? '我们的服务' : 'Our Services';
  const homeText = language === 'cn' ? '主页' : 'Home';
  const onlineOrderText = language === 'cn' ? '在线点餐' : 'Online Order';
  const menuText = language === 'cn' ? '菜单' : 'Menu';
  const topChefsText = language === 'cn' ? '顶级厨师' : 'Top Chefs';
  const eventHostingText = language === 'cn' ? '活动主持' : 'Event Hosting';
  const pickupText = language === 'cn' ? '店内取货' : 'Pickup In Store';
  const craftedWithText = language === 'cn' ? '制作' : 'Crafted With';

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <footer className="site-footer style-2 mt-5" id="footer">
        <div className="footer-bg-wrapper" id="app-banner">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                {/* Left Column for Contact Info and Business Hours */}
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 fadeInUp">
                  <div className="widget widget_getintuch">
                    <h5 className="footer-title text-black">{footerTitle}</h5>
                    <ul>
                      <li>
                        <a
                          className="text-black"
                          href="https://www.google.com/maps/place/Ten+Second+Rice+Noodle+Duluth+%E5%8D%81%E7%A7%92%E5%88%B0%E4%BA%91%E5%8D%97%E8%BF%87%E6%A1%A5%E7%B1%B3%E7%BA%BF/@33.9606441,-84.1364698,17z/data=!3m1!4b1!4m6!3m5!1s0x88f5a30e4c4a1fb5:0x684c93c2a4da8996!8m2!3d33.9606397!4d-84.1338949!16s%2Fg%2F11h8b4g_7z?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="flaticon-placeholder"></i>
                          {addressText}
                        </a>
                      </li>
                      <li>
                        <i className="flaticon-telephone"></i>
                        <a href="tel:+14707198448" className="text-black">
                          (470)-719-8448
                        </a>
                      </li>
                      <li>
                        <p className="text-black">
                          <strong className="text-black">{businessHoursText}:</strong><br />
                          {monToSunText}
                        </p>
                      </li>
                      <li>
                        <p className="text-black">
                          <strong className="text-black">{carryoutHoursText}:</strong><br />
                          {monToSunText}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                
                {/* Right Column for Links, Services, and More Info */}
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 text-center">
                  <div className="widget widget_services">
                    <h5 className="footer-title text-black">{quickLinksTitle}</h5>
                    <ul>
                      <li>
                        <Link to="/" className="text-black">{homeText}</Link>
                      </li>
                      <li>
                        <Link to="/order" className="text-black">{onlineOrderText}</Link>
                      </li>
                      <li>
                        <Link to="/menu" className="text-black">{menuText}</Link>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="widget widget_services mt-4">
                    <h5 className="footer-title text-black">{ourServicesTitle}</h5>
                    <ul>
                      <li>
                        <Link to="/" className="text-black">{topChefsText}</Link>
                      </li>
                      <li>
                        <Link to="/" className="text-black">{eventHostingText}</Link>
                      </li>
                      <li>
                        <Link to="/" className="text-black">{pickupText}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Bottom with Copyright and Heart Icon */}
          <div className="container">
            <div className="footer-bottom">
              <div className="row">
                <span className="copyright-text">
                  {craftedWithText}{" "}
                  <span
                    className="heart"
                    ref={heartRef}
                    onClick={() => {
                      heartRef.current?.classList.toggle("heart-blast");
                    }}
                  ></span>{" "}
                  by{" "}
                  <Link to="/">Crossing Bridge Rice Noodle</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer2;
