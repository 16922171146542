import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

function Order() {
    const { id, restaurant_id } = useParams();
    const [order, setOrder] = useState(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const res = await fetch(`https://hutaoadmin.onrender.com/api/orders/order/${id}/${restaurant_id}`);
                const data = await res.json();
                setOrder(data);
            } catch (error) {
                console.error("Error fetching order:", error);
            }
        };
        fetchOrder();
    }, [id, restaurant_id]);


    return (
        <div className="receipt-container mt-5">
            <div className="receipt-header">
                <Link to={`/order`} className="btn btn-primary mb-3">Order More</Link>
                <h1>Order Receipt</h1>
                <p className="text-danger"><strong>Order ID:</strong> {order?.order_id}</p>
            </div>
            {order && (
                <div className="receipt-body">
                    <div className="receipt-details">
                        <p><strong>Name:</strong> {order.name}</p>
                        <p><strong>Phone Number:</strong> {order.phone_number}</p>
                        <p><strong>Total:</strong> ${order.order_total}</p>
                        <p><strong>Payment Method:</strong> {order.payment_method}</p>
                        <p><strong>Order Status:</strong> {order.order_status}</p>
                        <p><strong>Address:</strong> {order.address}</p>
                        <p><strong>Order Date:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                        <p><strong>Subtotal:</strong> ${order.subtotal}</p>
                        <p><strong>Tax:</strong> ${order.tax}</p>
                        <p><strong>Convenience Fee:</strong> ${order.convenience_fee}</p>
                        <p><strong>Pickup Time:</strong> {order.pickup_time}</p>
                    </div>
                    {order.OrderItems && order.OrderItems.map((item, index) => (
                        <div className="receipt-item" key={item.id}>
                            <h5>{index + 1}. {item.Item.name} ({item.Item.chinese_name})</h5>
                            <p><strong>Price:</strong> ${item.item_price}</p>
                            <p><strong>Quantity:</strong> {item.quantity}</p>
                            <p><strong>Special Instructions:</strong> {item.special_instructions}</p>
                            {item.Order_Item_Attributes && item.Order_Item_Attributes.map(attr => (
                                <p key={attr.id}>
                                    <strong>{attr.Item_Attribute_Option.name}</strong>
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            )}
            <div className="receipt-footer">
                <p>Thank you for your order!</p>
            </div>
        </div>
    );
}

export default Order;
