import Swal from "sweetalert2";
export const isStoreOpen = () => {
  // Get the current date and time in the Eastern Time Zone
  const currentDate = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
  });

  // Extract the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const currentDayOfWeek = new Date(currentDate).getDay();

  // Extract the current time in HH:mm format
  const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
    hour12: false, // Use 24-hour format
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
  });

  // Define the store's opening hours in MDT (Mountain Daylight Time)
  const openingHours = [
    { day: 1, start: "10:30", end: "21:30" }, // Monday (Closed)
    { day: 2, start: "10:30", end: "21:30" }, // Tuesday (10:30 AM - 9:30 PM MDT -> 1:00 PM - 11:30 PM EST)
    { day: 3, start: "10:30", end: "21:30" }, // Wednesday (10:30 AM - 9:30 PM MDT -> 1:00 PM - 11:30 PM EST)
    { day: 4, start: "10:30", end: "21:30" }, // Thursday (10:30 AM - 9:30 PM MDT -> 1:00 PM - 11:30 PM EST)
    { day: 5, start: "10:30", end: "21:45" }, // Friday (10:30 AM - 10:00 PM MDT -> 1:00 PM - 12:00 AM EST)
    { day: 6, start: "10:30", end: "21:45" }, // Saturday (10:30 AM - 10:00 PM MDT -> 1:00 PM - 12:00 AM EST)
    { day: 0, start: "10:30", end: "21:30" }, // Sunday (12:00 PM - 9:00 PM MDT -> 2:00 PM - 10:30 PM EST)
  ];

  // Filter the opening hours for the current day
  const openingHour = openingHours.filter((hour) => hour.day === currentDayOfWeek);
  
  // If openingHour is undefined, it means the store is closed today
  if (openingHour.length === 0) {
    return false;
  }

  // Check if the current time is within any of the store's opening hours
  const isOpen = openingHour.some((hour) => {
    const startTime = new Date(`01/01/2022 ${hour.start}`);
    const endTime = new Date(`01/01/2022 ${hour.end}`);
    const currentTimeFormatted = new Date(`01/01/2022 ${currentTime}`);
    return currentTimeFormatted >= startTime && currentTimeFormatted <= endTime;
  });

  return true;
};


  export const checkIfLunchTime = () => {
    // Get the current date and time in the Eastern Time Zone
    const currentDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

    // Extract the current time in HH:mm format
    const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
        hour12: false, // Use 24-hour format
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/New_York", // Set the time zone to Eastern Time Zone
    });

    // Define the store's opening hours
    const openingHours = { start: "10:30", end: "2:30" };

    // Extract hours and minutes from current time
    const [currentHour, currentMinute] = currentTime.split(":").map(Number);

    // Extract hours and minutes from opening hours
    const [startHour, startMinute] = openingHours.start.split(":").map(Number);
    const [endHour, endMinute] = openingHours.end.split(":").map(Number);

    // Check if current time is within lunch hours
    const isLunchTime =
        (currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)) &&
        (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute));

    return isLunchTime;
};


export const showTimeError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Please come back later.',
      text: 'Hours: Mon-Sun 10:30 - 9:30PM, Fri-Sat: 10:30 - 9:45PM',

    })
  }
