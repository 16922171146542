import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import restaurantReducer from './restaurant';
import freeitemsReducer from './freeitems';
import couponReducer from './coupons';
import orderReducer from './order';
import stripeReducer from './stripe';



const rootReducer = combineReducers({
    restaurants: restaurantReducer,
    freeItems: freeitemsReducer,
    coupons: couponReducer,
    orders: orderReducer,
    stripe: stripeReducer
});

let enhancer;


if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require('redux-logger').default;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}


const configureStore = (preloadedState) => {
    return createStore(rootReducer, preloadedState, enhancer);
  };
  
  export default configureStore;
