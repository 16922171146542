import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../context/AppContext";

const Home2OurBlog = () => {
  const navigate = useNavigate();
  const { language } = useContext(Context);

  const titleText = language === 'cn' ? '关于十秒过桥米线' : 'About Ten Second Crossing the Bridge Rice Noodle';
  const storyText = language === 'cn'
    ? '十秒过桥米线（过桥米线，“Guò Qiáo Mǐ Xiàn”）的故事是来自中国云南的著名传说。传说中，有一位书生在小岛上专心备考，而他的妻子每天都会为他送餐。她需要经过一座桥，然而每次到达时，饭菜都会变冷。一天，她发现滚烫的鸡汤可以让食物保持温热，于是她便将食材（米线、肉、蔬菜）和汤分开放置，等到书生准备吃时再放入热汤中，这样可以保持食物的新鲜和温度。书生只需在滚烫的汤中快速烫煮食材，便可以立即食用——这道菜的名字也因此而来。这道菜的独特之处在于，汤的温度极高，只需几秒钟就可以煮熟米线和其他食材，因此现代称之为“十秒过桥米线”。'
    : 'The story of Ten Second Crossing the Bridge Rice Noodle (过桥米线, "Guò Qiáo Mǐ Xiàn") is a famous tale from Yunnan, China. According to legend, a scholar prepared for his exams on a small island, and his wife would bring him meals every day. The journey required her to cross a bridge, but by the time she arrived, the food would be cold. One day, she discovered that boiling hot chicken broth kept the food warm, and by separating the ingredients (noodles, meat, vegetables) from the soup until they were ready to eat, everything stayed fresh and hot. The scholar could quickly cook the ingredients in the hot broth right before eating — hence the dish’s name. The key feature of this dish is that the broth is so hot, it only takes seconds to cook the noodles and other ingredients once added, leading to its modern nickname: "Ten Second Crossing the Bridge Rice Noodle.';
  const deliveryInfoText = language === 'cn' ? '我们提供5英里范围内的送货服务' : 'We Offer Delivery Within 5 Miles';
  const buttonText = language === 'cn' ? '在线点餐' : 'Order Online';
  const restaurantname = language === 'cn' ? '十秒到过桥米线': 'Cross Bridge Rice Noodle'

  return (
    <section className="content-inner overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="section-head">
              <h2 className=" wow flipInX mb-0">{titleText}</h2>
            </div>
            <div className="testimonial-2 mt-0">
              <div className="testimonial-detail">
                <div className="testimonial-text wow fadeInUp">
                  <p>{storyText}</p>
                </div>
                <div className="testimonial-info wow fadeInUp">
                  <h5 className="testimonial-name">{deliveryInfoText}</h5>
                  <span className="testimonial-position">{restaurantname}</span>
                </div>
                <div
                  onClick={() => navigate("/order")}
                  className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
                >
                  <span>{buttonText}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home2OurBlog;
