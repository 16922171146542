import React, { useEffect } from 'react';

// Import all 13 images
import p1 from './photo/p1.jpg';
import p2 from './photo/p2.jpg';
import p3 from './photo/p3.jpg';
import p4 from './photo/p4.jpg';
import p5 from './photo/p5.jpg';
import p6 from './photo/p6.jpg';
import p7 from './photo/p7.jpg';
import p8 from './photo/p8.jpg';
import p9 from './photo/p9.JPG';
import p10 from './photo/p10.jpg';
import p11 from './photo/p11.jpg';
import p12 from './photo/p12.jpg';
import p13 from './photo/p13.jpg';

export default function RiceNoodleBanner() {
    const images = [p1, p2, p7, p4, p10, p6, p3, p13, p9, p5, p11, p12, p8];

    useEffect(() => {
        const elements = document.querySelectorAll('.gallery-item');

        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 } // Trigger when 10% of the element is visible
        );

        elements.forEach(el => observer.observe(el));

        return () => {
            elements.forEach(el => observer.unobserve(el));
        };
    }, []);

    return (
        <div className="gallery-container container">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`gallery-item gallery-item-${index + 1}`}
                >
                    <img src={image} alt={`Gallery image ${index + 1}`} className="gallery-image" />
                </div>
            ))}
        </div>
    );
}
