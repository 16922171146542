export function getItemAttributeNameById(id, item, lan) {
    // Iterate over Restaurant_Item_Attributes array
    for (const attribute of item.Restaurant_Item_Attributes) {
      // Iterate over Item_Attribute_Options array within each Item_Attribute
      for (const option of attribute.Item_Attribute.Item_Attribute_Options) {
        // Check if the id matches
        if (option.id === id) {
          return lan === 'en' ? option.name : option.chinese_name
        }
      }
    }
    // Return null if no matching id is found
    return null;
  }
